import { createApp } from 'vue'
import App from './App.vue'

import bootstrap from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App)
app.use(bootstrap)
app.mount('#app')
