<template>


  <div class="card">
    <div class="card-body d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <span class="fw-light fs-5">Талон #</span>
        <span class="fw-bold text-title">{{Num}}</span>
      </div>
      <i class="bi bi-arrow-right fs-1"></i>
      <div class="d-flex flex-column">
        <span class="fw-light fs-5">Окно/Терезе</span>
        <span class="fw-bold text-title">{{Win}}</span>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "ClientCard",
  props:["Num","Win"]
}
</script>

<style scoped>
  .text-title{
    font-size: 60px;
  }

</style>
