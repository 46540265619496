<template>
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid py-3 px-3 justify-content-between">
      <a class="navbar-brand" href="#">
        <img src="@/assets/logo.svg" alt="Logo"  class="d-inline-block align-text-top">
      </a>
      <span class="fs-1 fw-bold" style="color: #014694">Электронная очередь</span>
      <span class="fs-2">{{new Date().toLocaleDateString()}}</span>
    </div>
  </nav>
  <div class="container">
    <QueueTable/>
  </div>
</template>

<script>
import QueueTable from "@/components/QueueTable.vue";

export default {
  name: 'App',
  components: {
    QueueTable,
  },
  mounted() {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
</style>
