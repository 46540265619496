<template>
    <div v-if="!noTickets">
      <div class="row-cols-2 d-flex flex-wrap">
        <TransitionGroup  name="fade" mode="out-in">

        <ClientCard
            :class="{'col-5 mt-4 shadow rounded border-0':index%2===0,'col-5 mt-4 offset-1 shadow rounded border-0':true}"
            v-for="(ticket,index) in tickets" :key="ticket.id" :Win="ticket.window" :Num="ticket.number"/>
        </TransitionGroup >

      </div>
    </div>
    <div class="alert alert-info" v-else>
      <span class="fw-bold fs-1">Ожидаем заявки</span>
    </div>

  <div class="alert alert-info" v-if="id === null">
    <span class="fw-bold fs-1">Ошибка иницализации</span>
  </div>

</template>

<script>
import ClientCard from "@/components/ClientCard.vue";
import axios from "axios";

export default {
  name: "QueueTable",
  components: {ClientCard},
  data() {
    return {
      id: null,
      tickets: [],
      noTickets: true
    }
  },
  created: function () {
    let _this = this
    axios.get('https://queueapi.galereya.tech/getPullConfig.php')
        .then(function (response) {
          _this.id = new URL(location.href).searchParams.get('id')
          // window.history.pushState({}, "", window.location.href.split('?')[0]);
          if (_this.id !== null) {
            console.log("Starting connection to WebSocket Server")
            let url = response.data["result"]["server"]["websocket_secure"]+"?CHANNEL_ID="+response.data["result"]["channels"]["private"]["id"]+"&format=json"
            _this.connection = new WebSocket(url)
          }
        })
        .finally(function () {
          _this.connection.onmessage = function (event) {
            console.log(event)
            let data = event.data
            data = data.replace('#!NGINXNMS!#', '')
            data = data.replace('#!NGINXNME!#', '')
            data = JSON.parse(data)
            if (data["text"]["params"]["unique_id"] === _this.id) {
              _this.addTicket(data["text"]["params"]["ticket"])
            }
          }

          _this.connection.onopen = function (event) {
            console.log(event)
            console.log("Successfully connected to the echo websocket server...")
          }
        });



  },
  methods: {
    addTicket(data) {
      console.log(data)
      this.noTickets = false
      this.tickets.unshift(data)
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /*//transform: translateX(30px);*/
}
</style>
